import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import TextField from '@/components/TextField'

import FormikWithServerErrors from '@/utils/FormikWithServerErrors'

import styles from '../Forms.module.scss'

import { useUser } from '@/bus/user'
import { routes } from '@/config'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('errors:email.invalid')
    .required('errors:email.required'),
  password: Yup.string().required('errors:password.required')
})

const LoginForm: FC = () => {
  const { t } = useTranslation()
  const captchaRef = useRef<ReCAPTCHA>(null)
  const [captchaKey, setCaptchaKey] = useState(null)

  const { login, loading, errors: serverErrors = {}, startLoading } = useUser()

  useEffect(() => {
    // @ts-ignore
    setCaptchaKey(GOOGLE_CAPTCHA_SITE_KEY)
  }, [])

  const handleSubmit = useCallback(
    async (values: any) => {
      if (!captchaRef?.current || loading) {
        return
      }

      captchaRef.current.reset()
      captchaRef.current.execute()

      const token = await captchaRef.current.executeAsync()

      if (!token) {
        return
      }

      startLoading()

      const data = {
        ...values,
        'g-recaptcha-response': token
      }

      login({ data })
    },
    [loading, login, startLoading]
  )

  return (
    <>
      <Heading className={styles['heading']} level={3}>
        {t('auth:signin.login.heading')}
      </Heading>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnBlur
        validateOnChange
      >
        {({ setErrors }) => {
          return (
            <FormikWithServerErrors
              setErrors={setErrors}
              serverErrors={serverErrors}
            >
              <Form className={styles['form']} noValidate>
                {captchaKey && (
                  <div className={styles['form__captcha']}>
                    <ReCAPTCHA
                      ref={captchaRef}
                      size="invisible"
                      sitekey={captchaKey}
                    />
                  </div>
                )}
                <div>
                  <Field
                    label={t('forms:fields.email.label')}
                    className={styles['form__field']}
                    name="email"
                    type="email"
                    component={TextField}
                  />
                </div>
                <div>
                  <Field
                    className={styles['form__field']}
                    label={t('forms:fields.password.label')}
                    name="password"
                    type="password"
                    component={TextField}
                    helper={
                      <Link
                        title={t('forms:buttons.forgotPassword') as string}
                        className={styles['form__link']}
                        href={routes.RECOVERY}
                      >
                        {t('forms:buttons.forgotPassword')}
                      </Link>
                    }
                  />
                </div>

                <div className={styles['form__group-buttons']}>
                  <Button
                    title={t('forms:buttons.cancel') as string}
                    as={Link}
                    href={routes.HOME}
                    className={styles['form__button']}
                    variant={'light'}
                    size={'l'}
                  >
                    {t('forms:buttons.cancel')}
                  </Button>

                  <Button
                    title={t('forms:buttons.signin') as string}
                    className={styles['form__button']}
                    before={<Icon name={'arrows/right/28'} />}
                    type={'submit'}
                    size={'l'}
                    loading={loading}
                  >
                    {t('forms:buttons.signin')}
                  </Button>
                </div>
              </Form>
            </FormikWithServerErrors>
          )
        }}
      </Formik>
    </>
  )
}
export default LoginForm
